var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Main',[_c('ToolsPanel',{attrs:{"breadCrumbFirst":'Categories',"title":'Categories List'}},[_c('div',{staticClass:"list__buttons"},[_c('div',{staticClass:"list__search"},[_c('InputSearch',{attrs:{"search":_vm.handleSearch,"id":'search',"label":'search',"placeholder":'search...',"setValue":_vm.filterData}})],1),(_vm.isAdmin)?_c('MainButton',{nativeOn:{"click":function($event){return _vm.handleCreate.apply(null, arguments)}}},[_vm._v("Create")]):_vm._e()],1)]),_c('ContentContainer',[_c('div',{staticClass:"list"},[(_vm.categoriesList.length)?_c('div',[(_vm.categoriesList.length)?_c('CustomTable',{attrs:{"data":_vm.categoriesList,"deleted-keys":_vm.tableOptions.deletedKeys,"rename-columns":_vm.tableOptions.renameColumn,"custom-order":_vm.tableOptions.customOrder,"is-action-block-visible":_vm.tableOptions.isActionBlockVisible,"handle-delete":_vm.isAdmin ? _vm.handleDelete : false,"handle-edit":_vm.handleEdit,"handleIndexing":_vm.isAdmin ? _vm.handleIndexing : false,"handleFilter":_vm.handleFilter,"direction":_vm.directionSort,"order":_vm.orderSort,"name":'categories'}}):_vm._e(),_c('div',{staticClass:"pagination"},[_c('Pagination',{attrs:{"maxPage":_vm.categories.lastPage,"page":_vm.page,"dataList":_vm.categories,"urlList":'CATEGORIES_LIST',"queryParams":_vm.getQueryParameters}})],1)],1):_c('div',{staticClass:"list__no-have"},[_vm._v("No Categories found by this parameter.")])])]),_c('ModalError',{attrs:{"error-modal":false,"handle-close-error-modal":() => {
        this.error = {};
      }}}),(_vm.deleteModal)?_c('MainModal',{attrs:{"on-close":_vm.handleCloseConfirmModal,"modal-center":true}},[_c('div',{staticClass:"list__confirm"},[_c('div',{staticClass:"list__confirm-title"},[_vm._v(" Do you want to delete the Category - "+_vm._s(_vm.deleteRow.name)+"? ")]),_c('div',{staticClass:"list__confirm-buttons"},[_c('div',{staticClass:"list__confirm-button"},[_c('MainButton',{attrs:{"buttonWhite":""},nativeOn:{"click":function($event){return _vm.handleCloseConfirmModal.apply(null, arguments)}}},[_vm._v("CANCEL")])],1),_c('div',{staticClass:"list__confirm-button"},[_c('MainButton',{attrs:{"button-cancel":true},nativeOn:{"click":function($event){return _vm.handleDeleteCategories.apply(null, arguments)}}},[_vm._v("DELETE")])],1)])])]):_vm._e(),(_vm.indexingModal)?_c('MainModal',{attrs:{"on-close":() => {
        this.indexingModal = false;
      },"modal-center":true}},[_c('div',{staticClass:"list__confirm"},[(_vm.indexingRowData)?_c('div',[_c('div',{staticClass:"list__confirm-text"},[_vm._v("URL: "+_vm._s(_vm.indexingRowData.url))]),_c('div',{staticClass:"list__confirm-text"},[_vm._v("Status: "+_vm._s(_vm.indexingRowData.status.name))]),_c('div',{staticClass:"list__confirm-text"},[_vm._v("Checked At: "+_vm._s(_vm.indexingRowData.checkedAt))]),_c('div',{staticClass:"list__confirm-text"},[_vm._v(" Indexed: "+_vm._s(_vm.indexingRowData.indexed ? "yes" : "no")+" ")]),_c('div',{staticClass:"list__confirm-text"},[_vm._v("Indexed At: "+_vm._s(_vm.indexingRowData.indexedAt))])]):_c('div',[_c('div',{staticClass:"list__confirm-title"},[_vm._v("No page data. Add to index check queue")])]),_c('div',{staticClass:"list__confirm-buttons"},[_c('div',{staticClass:"list__confirm-button"},[_c('MainButton',{nativeOn:{"click":function($event){return _vm.handleCheck(_vm.indexingRow)}}},[_vm._v("CHECKING INDEX")])],1),_c('div',{staticClass:"list__confirm-button"},[_c('MainButton',{attrs:{"button-cancel":true},nativeOn:{"click":function($event){return _vm.handleAddIndex(_vm.indexingRow)}}},[_vm._v("ADD TO INDEX")])],1)])])]):_vm._e(),(_vm.deleteModalSuccess)?_c('MainModal',{attrs:{"on-close":_vm.handleCloseSuccessModal}},[_c('div',{staticClass:"list__success-delete"},[_vm._v("Category "+_vm._s(_vm.deleteRow.name)+" successfully deleted.")])]):_vm._e(),(_vm.modalSuccess)?_c('MainModal',{attrs:{"on-close":() => {
        this.modalSuccess = false;
      }}},[_c('div',{staticClass:"list__success-delete"},[_vm._v("Completed successfully")])]):_vm._e(),(_vm.errorModal)?_c('MainModal',{attrs:{"on-close":() => {
        this.errorModal = false;
      }}},[_c('div',{staticClass:"list__success-delete"},[_vm._v(_vm._s(_vm.errorModalText))])]):_vm._e(),(_vm.loadingProcess)?_c('FetchSpinnerModal'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }