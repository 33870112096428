<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Categories'" :title="'Categories List'">
      <div class="list__buttons">
        <div class="list__search">
          <InputSearch
            :search="handleSearch"
            :id="'search'"
            :label="'search'"
            :placeholder="'search...'"
            :setValue="filterData"
          />
        </div>
        <MainButton v-if="isAdmin" @click.native="handleCreate">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="categoriesList.length">
          <CustomTable
            v-if="categoriesList.length"
            :data="categoriesList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="isAdmin ? handleDelete : false"
            :handle-edit="handleEdit"
            :handleIndexing="isAdmin ? handleIndexing : false"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :order="orderSort"
            :name="'categories'"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="categories.lastPage"
              :page="page"
              :dataList="categories"
              :urlList="'CATEGORIES_LIST'"
              :queryParams="getQueryParameters"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Categories found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Category - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteCategories" :button-cancel="true"
              >DELETE</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal
      v-if="indexingModal"
      :on-close="
        () => {
          this.indexingModal = false;
        }
      "
      :modal-center="true"
    >
      <div class="list__confirm">
        <div v-if="indexingRowData">
          <div class="list__confirm-text">URL: {{ indexingRowData.url }}</div>
          <div class="list__confirm-text">Status: {{ indexingRowData.status.name }}</div>
          <div class="list__confirm-text">Checked At: {{ indexingRowData.checkedAt }}</div>
          <div class="list__confirm-text">
            Indexed: {{ indexingRowData.indexed ? "yes" : "no" }}
          </div>
          <div class="list__confirm-text">Indexed At: {{ indexingRowData.indexedAt }}</div>
        </div>
        <div v-else>
          <div class="list__confirm-title">No page data. Add to index check queue</div>
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton @click.native="handleCheck(indexingRow)">CHECKING INDEX</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleAddIndex(indexingRow)" :button-cancel="true"
              >ADD TO INDEX</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Category {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <MainModal
      v-if="modalSuccess"
      :on-close="
        () => {
          this.modalSuccess = false;
        }
      "
    >
      <div class="list__success-delete">Completed successfully</div>
    </MainModal>
    <MainModal
      v-if="errorModal"
      :on-close="
        () => {
          this.errorModal = false;
        }
      "
    >
      <div class="list__success-delete">{{ errorModalText }}</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import categoriesApi from "~/api/categories";
import indexingApi from "~/api/indexing";
import { categoriesTableList } from "~/helpers/dataIteration";
import { getStoredUserInfo } from "~/helpers/userAuth";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";

export default {
  name: "CategoriesGroupsList",
  metaInfo: {
    title: "Categories Groups List",
  },
  data() {
    return {
      isAdmin: false,
      isManager: false,
      isLeadDesigner: false,
      isCopywriter: false,
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: false,
      orderSort: "id",
      modalSuccess: false,
      indexingRow: null,
      indexingModal: false,
      indexingRowData: null,
      indexingModalSuccess: false,
      tableOptions: {
        deletedKeys: ["null", "url"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "seo_name",
            to: "SEO Name",
          },
          {
            from: "status",
            to: "Status",
          },
          {
            from: "updated_at",
            to: "Last Update",
          },
          {
            from: "templates_count",
            to: "Templates",
          },
        ],
        customOrder: [
          "id",
          "name",
          "seo_name",
          "status",
          "updated_at",
          "templates_count",
          "url",
          "null",
        ],
      },
      filterData: {
        search: null,
      },
      errorModal: false,
      errorModalText: "",
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputSearch,
  },
  mounted() {
    if (getStoredUserInfo()) {
      const user = getStoredUserInfo();
      for (let i = 0; user.groups.length > i; i++) {
        if (user.groups[i].id == 1 || user.groups[i].id == 2) {
          this.isAdmin = true;
        }

        if (user.groups[i].id == 5) {
          this.isLeadDesigner = true;
        }

        if (user.groups[i].id == 59) {
          this.isManager = true;
        }

        if (user.groups[i].id == 6) {
          this.isCopywriter = true;
        }
      }
    }

    this.setQueryParams();
    // this.getCategoriesList();
  },
  watch: {
    $route(to, from) {
      this.getCategoriesList();
    },
  },
  computed: {
    ...mapState("categories", ["error", "loadingProcess", "categories", "categoriesList"]),
    page() {
      return +this.$route.params.page;
    },
    getQueryParameters() {
      const query = this.$route.fullPath.split("?")[1];
      if (query) {
        return `?${query}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    getCategoriesList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.filterData.search != null) {
        const data = {};
        data.search = this.filterData.search;
        this.$store.commit("categories/setLoading", true);
        categoriesApi
          .getCategoriesListFilter(`?page=${this.page}`, data)
          .then((res) => {
            this.$store.commit("categories/setError", {});
            this.$store.commit("categories/setValidationErrors", {});
            this.$store.commit(
              "categories/setCategoriesList",
              categoriesTableList(res.data.items.data),
            );
            this.$store.commit("categories/setCategories", res.data.items);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("categories/setLoading", false);
          });
      } else {
        this.$store.dispatch("categories/getCategoriesList", url);
      }
    },
    handleGetQueryParam(param) {
      if (this.$route.query[param] != undefined) {
        return this.$route.query[param];
      } else {
        return null;
      }
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getCategoriesList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.CATEGORY_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.CATEGORY_CREATE,
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteCategories() {
      const url = `/${this.deleteRow.id}?soft=1`;
      this.$store.commit("categories/setLoading", true);
      categoriesApi
        .deleteCategory(url)
        .then((res) => {
          this.$store.commit("categories/setLoading", false);
          if (res.success) {
            this.getCategoriesList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          } else {
            this.handleCloseConfirmModal();
            this.errorModal = true;
            this.errorModalText = res.message[0];
          }
        })
        .catch((error) => {
          this.$store.commit("categories/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
    setQueryParams() {
      if (this.handleGetQueryParam("search")) {
        this.filterData.search = this.handleGetQueryParam("search");
      }

      this.getCategoriesList();
    },
    handleSetQueryParams() {
      const filter = {};
      if (this.filterData.search != null) {
        filter.search = this.filterData.search;
      }
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.CATEGORIES_LIST.replace(":page", 1),
        });
      }
      this.$router.push({
        name: "CategoriesList",
        query: filter,
      });
    },
    handleSearch() {
      this.handleSetQueryParams();
    },
    handleIndexing(row) {
      this.indexingRow = row;
      this.indexingModal = true;
      this.indexingRowData = null;
      this.$store.commit("categories/setLoading", true);
      const url = `?path=${row.url}`;
      indexingApi
        .getIndexingItem(url)
        .then((res) => {
          this.$store.commit("categories/setLoading", false);

          if (res.success) {
            this.indexingRowData = res.data.item;
          }
        })
        .catch((error) => {
          this.$store.commit("categories/setLoading", false);
          this.indexingRowData = null;
          console.log(error);
        });
    },
    handleCheck(row) {
      if (row.url.length > 0) {
        const data = {
          paths: [row.url],
        };
        this.$store.commit("categories/setLoading", true);
        indexingApi
          .checkIndexing(data)
          .then((res) => {
            console.log(res);
            this.$store.commit("categories/setLoading", false);
            if (res.success) {
              this.modalSuccess = true;
              this.handleIndexing(row);
            }
          })
          .catch((error) => {
            this.$store.commit("categories/setLoading", false);
            console.log(error);
          });
      }
    },
    handleAddIndex(row) {
      this.$store.commit("categories/setLoading", true);
      if (row.url.length > 0) {
        const data = {
          paths: [row.url],
        };
        indexingApi
          .addToIndexing(data)
          .then((res) => {
            this.$store.commit("categories/setLoading", false);
            if (res.success) {
              this.modalSuccess = true;
              this.handleIndexing(row);
            }
          })
          .catch((error) => {
            this.$store.commit("categories/setLoading", false);

            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__buttons {
    display: flex;
  }
  &__search {
    min-width: 250px;
    margin-right: 20px;
  }
}
/deep/.custom-table__header {
  grid-template-columns: 0.6fr 3fr 1.6fr 0.6fr 1fr 0.6fr 0.6fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 0.6fr 3fr 1.6fr 0.6fr 1fr 0.6fr 0.6fr !important;
}
</style>
